/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

li {
    margin-top: 8px;
    margin-bottom: 8px;
  }

.code {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: rgb(220, 0, 78, 0.1);
}
.light-red {
    background-color: rgb(220, 0, 78, 0.1);
}
/* Material design colors */
/* https://material.io/design/color/the-color-system.html */
.red {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #FFCDD2;
}
.pink {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #F8BBD0;
}
.purple {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #E1BEE7;
}
.deep-purple {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #D1C4E9;
}
.indigo {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #C5CAE9;
}
.light-indigo {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #e8eaf6;
}
.blue {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #BBDEFB;
}
.light-blue {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #B3E5FC;
}
.cyan {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #B2EBF2;
}
.teal {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #B2DFDB;
}
.green {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #C8E6C9;
}
.light-green {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #DCEDC8;
}
.lime {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #F0F4C3;
}
.yellow {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #FFF9C4;
}
.amber {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #FFECB3;
}
.orange {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #FFE0B2;
}
.deep-orange {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #FFCCBC;
}
.brown {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #D7CCC8;
}
.gray {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #EEEEEE;
}
.blue-gray {
    font-family: 'Roboto', 'Consolas', 'Lucida Console';
    background-color: #CFD8DC;
}
